<template>
  <div>
    <v-card>
      <v-row>
        <v-col>
          <v-card-title> Calendario Asignaciones </v-card-title>
          <v-card-subtitle> </v-card-subtitle>
        </v-col>
      </v-row>

      <v-divider></v-divider>
      <v-card-text>
        <v-row>
          <v-col>
            <div>
              <v-sheet tile height="54" class="d-flex">
                <v-btn icon class="ma-2" @click="$refs.calendar.prev()">
                  <v-icon>mdi-chevron-left</v-icon>
                </v-btn>

                <v-spacer></v-spacer>
                <v-btn icon class="ma-2" @click="$refs.calendar.next()">
                  <v-icon>mdi-chevron-right</v-icon>
                </v-btn>
              </v-sheet>
              <v-sheet height="600">
                <v-progress-circular
                  v-if="loading"
                  indeterminate
                  color="primary"
                ></v-progress-circular>
                <v-calendar
                  v-else
                  ref="calendar"
                  color="primary"
                  v-model="value"
                  :weekdays="weekday"
                  :type="type"
                  :events="events"
                  :event-overlap-threshold="30"
                  :event-color="getEventColor"
                  :start="
                    start
                      ? start
                      : new Date().getFullYear() +
                        '-' +
                        new Date().getMonth() +
                        '-' +
                        new Date().getDate()
                  "
                  @change="getEvents"
                  locale="es"
                ></v-calendar>
              </v-sheet>
            </div>
          </v-col>
        </v-row>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "TurnosAsignadosCalendarioCard",
  data() {
    return {
      fecha: null,
      events: [],
      /* */
      type: "month",
      types: ["month", "week", "day", "4day"],
      mode: "stack",
      modes: ["stack", "column"],
      weekday: [1, 2, 3, 4, 5, 6, 0],
      weekdays: [
        { text: "Sun - Sat", value: [0, 1, 2, 3, 4, 5, 6] },
        { text: "Mon - Sun", value: [1, 2, 3, 4, 5, 6, 0] },
        { text: "Mon - Fri", value: [1, 2, 3, 4, 5] },
        { text: "Mon, Wed, Fri", value: [1, 3, 5] },
      ],
      value: "",
    };
  },
  props: {
    asignaciones: {
      type: Array,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
    },
    start: {
      type: String,
      required: false,
    },
  },
  created() {},
  methods: {
    asignacionesToEvents() {
      this.events = [];
      this.asignaciones.forEach((element) => {
        // console.log("----------------")
        // console.log(element.desde);
        // console.log(element.hasta);
        // console.log(element.lugar ? element.lugar.nombre : "");
        this.events.push({
          name:
            element.turno.nombre +
            (element.lugar ? "\n" + element.lugar.nombre : ""),
          start: element.desde,
          end: element.hasta ? element.hasta : "2999-01-01",
          color: element.turno.color ? element.turno.color : "blue",
          timed: false,
        });
      });
    },

    getEvents() {
      return this.events;
    },

    getEventColor(event) {
      return event.color;
    },
  },
  watch: {
    asignaciones(val) {
      val && val !== this.asignacionesToEvents();
    },
  },
};
</script>
