<template>
  <v-autocomplete
    v-model="selLugar"
    :items="lugares"
    item-text="nombre"
    item-value="id"
    label="Lugar"
    cache-items
    :loading="loading"
    :search-input.sync="searchLugar"
    no-data-text="No se encontraron resultados"
  >
  </v-autocomplete>
</template>

<script>
import EmpleadosService from "@/services/EmpleadosService";

export default {
  name: "LugarAutocomplete",
  components: {},

  data() {
    return {
      formValidity: false,
      searchLugar: null,
      selLugar: null,
      lugares: [],
      loading: false,
      lugaresRules: [(v) => !!v || "Debe seleccionar un lugar."],
    };
  },
  props: {
    lugarGuardado: {
      type: Object,
      required: false,
    },
  },
  created() {
    this.loading = false;
    if (this.lugarGuardado) {
      this.selLugar = this.lugarGuardado;
      this.lugares.push(this.lugarGuardado);
    }
  },
  methods: {
    // emitirClick() {
    //   this.$emit("autoriza-click", this.selLugares);
    // },

    querySelections(v) {
      if (v.length < 3) return;
      this.loading = true;
      EmpleadosService.getLugares(v)
        .then((response) => {
          this.lugares = response.data.results;
        })
        .catch((error) => {
          this.loading = false;
          console.log(error);
        });

      this.loading = false;
    },
  },
  watch: {
    searchLugar(val) {
      val && val !== this.selLugar && this.querySelections(val);
    },
  },
};
</script>
