<template>
  <v-autocomplete
    v-model="selTurno"
    :items="turnos"
    item-text="nombre"
    item-value="id"
    label="Turno"
    cache-items
    :loading="loading"
    :rules="autoRules"
    :search-input.sync="searchTurno"
    no-data-text="No se encontraron resultados"
  >
  </v-autocomplete>
</template>

<script>
import PresentismoService from "@/services/PresentismoService";

export default {
  name: "TurnoAutocomplete",
  components: {},

  data() {
    return {
      formValidity: false,
      selTurno: null,
      searchTurno: null,
      turnos: [],
      loading: false,
      autoRules: [(v) => !!v || "Debe seleccionar un elemento."],
    };
  },
  props: {
    turnoGuardado: {
      type: Object,
      required: false,
    },
  },
  created() {
    if (this.turnoGuardado) {
      this.selTurno = this.turnoGuardado;
      this.turnos.push(this.turnoGuardado);
    }
    // console.log("EG: " + this.turnoGuardado + " SE: " + this.selTurno);
  },
  methods: {
    // getTurnoText(item) {
    //   return `${item.apellidos}, ${item.nombres} (${item.documento_nro})`;
    // },
    querySelections(v) {
      if (v.length < 3) return;
      this.loading = true;

      PresentismoService.getTurnos(v)
        .then((response) => {
          this.turnos = response.data.results;
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          console.log(error);
        });
    },
  },
  watch: {
    searchTurno(val) {
      val && val !== this.selTurno && this.querySelections(val);
    },
  },
};
</script>
