<template>
  <v-container>
    <v-row>
      <v-col>
        <span class="text-h4">Asignar Turno</span>
      </v-col>
    </v-row>
    <v-divider> </v-divider>
    <br />
    <div>
      <TurnoAsignarForm
        @empleado-changed="actualizarCalendario"
        @desde-changed="actualizarComienzoCalendario"
      ></TurnoAsignarForm>
    </div>
    <br />
    <div>
      <TurnosAsignadosCalendarioCard
        ref="calendar"
        :loading="this.calendarLoading"
        :asignaciones="this.empleadoTurnos"
        :start="comienzoCalendario ? comienzoCalendario : null"
      ></TurnosAsignadosCalendarioCard>
    </div>
  </v-container>
</template>
<script>
import PresentismoService from "../services/PresentismoService";
import TurnoAsignarForm from "../components/TurnoAsignarForm.vue";
import TurnosAsignadosCalendarioCard from "../components/TurnosAsignadosCalendarioCard.vue";

export default {
  name: "TurnoAsignar",
  components: {
    TurnoAsignarForm,
    TurnosAsignadosCalendarioCard,
  },
  data() {
    return {
      empleadoTurnos: [],
      calendarLoading: false,
      comienzoCalendario: "",
    };
  },
  created() {},
  methods: {
    actualizarComienzoCalendario(event) {
      this.comienzoCalendario = event;
    },
    actualizarCalendario(event) {
      if (!event) {
        this.empleadoTurnos = [];
        return;
      }

      this.calendarLoading = true;
      PresentismoService.getTurnosEmpleados(event, 1)
        .then((response) => {
          this.empleadoTurnos = response.data;
          this.calendarLoading = false;
          // console.log("Turnos Empleado: " + this.empleadoTurnos);
          // this.$refs.calendar.asignacionesToEvents();
        })
        .catch((error) => {
          console.log(error);
          this.loadingSave = false;
        });
    },
  },
};
</script>
