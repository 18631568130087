<template>
  <v-card>
    <v-card-title>Datos de la Asignación</v-card-title>
    <v-container v-if="error">
      <v-row>
        <v-col>
          <v-alert dense type="error">{{ error }}</v-alert>
        </v-col>
      </v-row>
    </v-container>
    <v-form v-model="formValidity" ref="form" @submit.prevent="">
      <v-container>
        <v-row>
          <v-col cols="12" md="4">
            <EmpleadoAutocomplete
              ref="empleadoAutoComplete"
              :empleadoGuardado="empleado"
              :conContratacion="true"
              @empleado-changed="onEmpleadoChanged"
            ></EmpleadoAutocomplete>
          </v-col>
          <v-col cols="12" md="4">
            <TurnoAutocomplete
              ref="turnoAutoComplete"
              :turnoGuardado="turno"
            ></TurnoAutocomplete>
          </v-col>
          <v-col cols="12" md="4">
            <LugarAutocomplete
              ref="lugarAutoComplete"
              :lugarGuardado="lugar"
            ></LugarAutocomplete>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="4">
            <v-menu
              ref="menuFechaDesde"
              v-model="menuDesde"
              :close-on-content-click="false"
              :return-value.sync="fechaDesde"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="fechaDesde"
                  label="Desde"
                  prepend-icon="mdi-calendar"
                  readonly
                  :rules="fechaDesdeRules"
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="fechaDesde"
                no-title
                scrollable
                locale="es"
                @change="handleDateFromChange"
              >
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="menuDesde = false">
                  Cancelar
                </v-btn>
                <v-btn
                  text
                  color="primary"
                  @click="$refs.menuFechaDesde.save(fechaDesde)"
                >
                  OK
                </v-btn>
              </v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="12" md="4">
            <v-menu
              ref="menuFechaHasta"
              v-model="menuHasta"
              :close-on-content-click="false"
              :return-value.sync="fechaHasta"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="fechaHasta"
                  label="Hasta"
                  prepend-icon="mdi-calendar"
                  readonly
                  :rules="fechaHastaRules"
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="fechaHasta"
                no-title
                scrollable
                locale="es"
              >
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="menuHasta = false">
                  Cancelar
                </v-btn>
                <v-btn
                  text
                  color="primary"
                  @click="$refs.menuFechaHasta.save(fechaHasta)"
                >
                  OK
                </v-btn>
              </v-date-picker>
            </v-menu>
          </v-col>
        </v-row>
        <v-divider> </v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            :disabled="!formValidity"
            color="primary"
            type="submit"
            :loading="loadingSave"
            @click="onSubmit(false)"
            ><v-icon left dark> mdi-check </v-icon>Guardar</v-btn
          >
          <v-btn
            :disabled="!formValidity"
            color="primary"
            type="submit"
            :loading="loadingSave"
            @click="onSubmit(true)"
            ><v-icon left dark> mdi-check </v-icon>Guardar y Asignar Otro</v-btn
          >
          <v-btn color="error" to="/turnos"
            ><v-icon left dark> mdi-close </v-icon>Cancelar</v-btn
          >
        </v-card-actions>
      </v-container>
    </v-form>
  </v-card>
</template>

<script>
import PresentismoService from "../services/PresentismoService";
import EmpleadoAutocomplete from "../components/autocompletes/EmpleadoAutocomplete.vue";
import TurnoAutocomplete from "../components/autocompletes/TurnoAutocomplete.vue";
import LugarAutocomplete from "../components/autocompletes/LugarAutocomplete.vue";
import Helpers from "../../../ControlTss-Reloj-Web/src/helpers/helpers.js";
import moment from "moment";

export default {
  name: "TurnoAsignarForm",
  components: {
    EmpleadoAutocomplete,
    TurnoAutocomplete,
    LugarAutocomplete,
  },

  data() {
    return {
      empleado: null,
      turno: null,
      lugar: null,
      formValidity: false,
      nombre: "",
      error: false,
      menuDesde: false,
      fechaDesde: null,
      menuHasta: false,
      fechaHasta: null,
      loadingSave: false,
      fechaDesdeRules: [(v) => !!v || "Debe seleccionar una opción"],
      fechaHastaRules: [
        (v) =>
          moment(v).format("YYYY-MM-DD") >=
            moment(this.fechaDesde).format("YYYY-MM-DD") ||
          'La fecha "Hasta" debe ser igual o mayor a la fecha "Desde"',
      ],
      turnosEmpleado: [],
    };
  },
  props: {
    turnoEmpleado: {
      type: Object,
      required: false,
    },
  },
  created() {
    if (this.turnoEmpleado) {
      this.empleado = this.turnoEmpleado.empleado;
      this.turno = this.turnoEmpleado.turno;
      this.lugar = this.turnoEmpleado.lugar;
      this.fechaDesde = this.turnoEmpleado.desde;
      this.fechaHasta = this.turnoEmpleado.hasta;
      this.$refs.empleadoAutoComplete.disabled = true;
    }
  },
  mounted() {
    if (this.turnoEmpleado) this.$refs.empleadoAutoComplete.disabled = true;
  },

  methods: {
    handleDateFromChange(event) {
      this.$emit("desde-changed", event);
    },
    onEmpleadoChanged(event) {
      this.$emit("empleado-changed", event);
    },
    afterSave(otro) {
      if (otro) {
        this.$router.go();
      } else {
        this.$router.push({
          name: "Turnos",
        });
      }
    },

    onSubmit(asignarOtro) {
      this.loadingSave = true;

      // console.log("turnoempleadoid: " + this.turnoEmpleado.empleado);
      // console.log(
      //   "autocomplete_id: " + this.$refs.empleadoAutoComplete.selEmpleado.id
      // );
      // console.log(
      //   "autocomplete: " + this.$refs.empleadoAutoComplete.selEmpleado
      // );

      let turnoEmpleadoInstance = {
        empleado: this.turnoEmpleado
          ? this.turnoEmpleado.empleado.id
          : this.$refs.empleadoAutoComplete.selEmpleado,
        turno: this.turnoEmpleado
          ? this.$refs.turnoAutoComplete.selTurno.id
          : this.$refs.turnoAutoComplete.selTurno,
        desde: this.fechaDesde,
        hasta: this.fechaHasta,
        lugar: this.lugar
          ? this.$refs.lugarAutoComplete.selLugar.id
          : this.$refs.lugarAutoComplete.selLugar,
      };

      if (this.turnoEmpleado) {
        turnoEmpleadoInstance.id = this.turnoEmpleado.id;
        PresentismoService.putTurnoEmpleado(turnoEmpleadoInstance)
          .then((response) => {
            this.afterSave(asignarOtro);
          })
          .catch((error) => {
            this.error = Helpers.handleError(error);
            this.loadingSave = false;
          });
      } else {
        PresentismoService.postTurnoEmpleado(turnoEmpleadoInstance)
          .then((response) => {
            this.afterSave(asignarOtro);
          })
          .catch((error) => {
            this.error = Helpers.handleError(error);
            this.loadingSave = false;
          });
      }
    },
    validate() {
      this.$refs.form.validate();
    },
  },
};
</script>
<style>
.overlay {
  position: absolute;
  left: 100%;
  transform: translateX(-96%) !important;
  height: 100%;
  width: auto;
  display: flex;
}
</style>
